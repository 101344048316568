import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface ContainerProps extends BoxProps {
  fluid?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  fluid,
  children,
  ...restProps
}) => {
  return (
    <Box
      {...restProps}
      w={fluid ? 11 / 12 : "full"}
      maxW={fluid ? "1536px" : "auto"}
      mx={fluid ? "auto" : 0}
    >
      {children}
    </Box>
  );
};

export default Container;

import React from "react";
import { Grid, HStack, VStack, Icon, Text } from "@chakra-ui/react";
import Container from "components/ui/Container";
import { Link } from "gatsby";
import { MdLocationPin, MdPhone, MdEmail } from "react-icons/md";
import { StaticImage } from "gatsby-plugin-image";

const QUICK_LINK_ITEMS = [
  {
    to: "/",
    text: "Home",
  },
  {
    to: "/about-us/",
    text: "About us",
  },
  {
    to: "/product/",
    text: "Product",
  },
  {
    to: "/solution/",
    text: "Solution",
  },
  {
    to: "/pricing/",
    text: "Pricing",
  },
  {
    to: "/training-center/",
    text: "Training center",
  },
  {
    to: "/privacy-policy/",
    text: "Privacy policy",
  },
  {
    to: "/terms-and-conditions/",
    text: "Terms & conditions",
  },
];

const Footer: React.FC = () => {
  return (
    <footer className="bg-white space-y-1 text-white">
      <div className="bg-gray-800 py-8">
        <Container fluid>
          <Grid
            templateColumns={{
              base: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(3, 1fr)",
            }}
            gap={6}
          >
            <VStack spacing={4} alignItems="flex-start">
              <span className="font-bold text-2xl">AiHouse India</span>

              <p>
                AiHouse India is a high-tech pioneer of 3D cloud designing,
                cloud manufacturing, AI and Big Data in home furnishing
                industry, transforming furniture purchasing and home designing
                with its integrated online platform.
              </p>
            </VStack>

            <VStack spacing={4} alignItems="flex-start">
              <span className="font-bold text-2xl">Quick Links</span>

              <VStack as="ul" spacing={1} alignItems="flex-start">
                {QUICK_LINK_ITEMS.map(item => (
                  <li key={item.to}>
                    <Link to={item.to} className="hover:underline">
                      {item.text}
                    </Link>
                  </li>
                ))}
              </VStack>
            </VStack>

            <VStack spacing={4} alignItems="flex-start">
              <span className="font-bold text-2xl">Corporate Office</span>

              <VStack as="ul" spacing={2} alignItems="flex-start">
                <HStack as="li" alignItems="flex-start">
                  <Icon as={MdLocationPin} color="orange.500" h={8} w={8} />
                  <Text
                    as="a"
                    className="leading-tight"
                    href="https://goo.gl/maps/U6KszEb1izZbRgVc6"
                    target="_blank"
                    rel="noreferrer noopener"
                    _hover={{ color: "orange", textDecor: "underline" }}
                  >
                    Emaar Digital Greens, Tower-B, 903, Golf Course Road
                    Extension, Sector-61, Gurugram, Haryana, India 122102
                  </Text>
                </HStack>
                <HStack as="li">
                  <Icon as={MdPhone} color="orange.500" h={8} w={8} />
                  <Text
                    as="a"
                    className="leading-tight hover:underline"
                    href="tel:+919311481188."
                    _hover={{ color: "orange", textDecor: "underline" }}
                  >
                    +91 9311481188
                  </Text>
                </HStack>
                <HStack as="li">
                  <Icon as={MdEmail} color="orange.500" h={8} w={8} />
                  <Text
                    as="a"
                    className="leading-tight hover:underline"
                    href="mailto:contactaihouseindia@gmailcom"
                    _hover={{ color: "orange", textDecor: "underline" }}
                  >
                    contactaihouseindia@gmail.com
                  </Text>
                </HStack>
              </VStack>

              <VStack as="ul" spacing={1} alignItems="flex-start">
                <span className="text-xl">Subscribe &amp; Follow</span>

                <HStack>
                  <a
                    href="https://www.facebook.com/AiHouse-India-104755388680195"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <StaticImage
                      src="../../images/icons/social/facebook.png"
                      alt="Facebook"
                      height={32}
                      width={32}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/aihouseindia/"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <StaticImage
                      src="../../images/icons/social/instagram.png"
                      alt="Instagram"
                      height={32}
                      width={32}
                    />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCECiZwLf3jHv2pvnJAQEfIw"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <StaticImage
                      src="../../images/icons/social/youtube.png"
                      alt="YouTube"
                      height={32}
                      width={32}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/aihouse-india/"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    <StaticImage
                      src="../../images/icons/social/linkedin.png"
                      alt="LinkedIn"
                      height={32}
                      width={32}
                    />
                  </a>
                </HStack>
              </VStack>
            </VStack>
          </Grid>
        </Container>
      </div>
      <div className="bg-black py-2">
        <Container fluid>
          <p className="text-sm text-center">
            Copyright &copy; AiHouse India {new Date().getFullYear()}. All
            rights reserved.
          </p>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;

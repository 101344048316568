import React, { useState, useEffect } from "react";
import Container from "components/ui/Container";
import { StaticImage } from "gatsby-plugin-image";
import { Spacer, IconButton, Stack, Button, Box } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import * as styles from "./styles.module.scss";
import useMediaQuery from "@pijushbarik/use-media-query";

const NAV_MENU_ITEMS = [
  {
    to: "/",
    text: "Home",
  },
  {
    to: "/about-us/",
    text: "About us",
  },
  {
    to: "/product/",
    text: "Product",
  },
  {
    to: "/solution/",
    text: "Solution",
  },
  {
    to: "/pricing/",
    text: "Pricing",
  },
  {
    to: "/training-center/",
    text: "Training center",
  },
  {
    to: "/design-gallery/",
    text: "Design gallery",
  },
  {
    to: "/contact-us/",
    text: "Contact us",
  },
];

const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [pathname, setPathname] = useState(() => location.pathname);
  const isCollapseMenuBreakpoint = useMediaQuery<boolean>(
    ["(max-width: 910px)"],
    [true],
    false
  );
  const isShowLogoIconOnlyBreakpoint = useMediaQuery<boolean>(
    ["(min-width: 1200px)", "(min-width: 910px)"],
    [false, true],
    false
  );

  useEffect(() => {
    if (location.pathname !== pathname) {
      setPathname(location.pathname);
      setOpen(false);
    }
  }, [location.pathname]);

  return (
    <Box
      as="header"
      py={4}
      backgroundColor="gray.100"
      position="fixed"
      top={0}
      left={0}
      w="full"
      zIndex={10}
      shadow="md"
    >
      <Container fluid>
        <Stack alignItems="center" position="relative" direction="row">
          <Link to="/">
            {isShowLogoIconOnlyBreakpoint ? (
              <StaticImage
                src="../../images/icon.png"
                alt="AiHouse India"
                width={48}
                height={48}
              />
            ) : (
              <StaticImage
                src="../../images/aihouse-india-logo.png"
                alt="AiHouse India"
                width={190}
              />
            )}
          </Link>

          <Spacer />

          {isCollapseMenuBreakpoint ? (
            <IconButton
              aria-label="Menu"
              icon={open ? <CloseIcon /> : <HamburgerIcon />}
              onClick={() => setOpen(!open)}
              colorScheme="orange"
            />
          ) : null}

          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            display={!isCollapseMenuBreakpoint || open ? "flex" : "none"}
            position={isCollapseMenuBreakpoint ? "absolute" : "relative"}
            left={0}
            top={isCollapseMenuBreakpoint ? "56px" : "auto"}
            width={isCollapseMenuBreakpoint ? "full" : "auto"}
            backgroundColor="gray.100"
            zIndex={10}
            py={isCollapseMenuBreakpoint ? 4 : 0}
            shadow={isCollapseMenuBreakpoint ? "md" : "none"}
            borderBottomRadius={isCollapseMenuBreakpoint ? "lg" : "none"}
          >
            <Stack
              spacing={{ base: 3, md: 2, lg: 4 }}
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              width={isCollapseMenuBreakpoint ? "full" : "auto"}
              px={isCollapseMenuBreakpoint ? 4 : 0}
            >
              {NAV_MENU_ITEMS.map(item => (
                <Link
                  to={item.to}
                  className={styles.navButton}
                  activeClassName={styles.navButton__active}
                  key={item.to}
                >
                  {item.text}
                </Link>
              ))}
            </Stack>

            <Stack
              justifyContent="flex-end"
              spacing={0.5}
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "flex-start", md: "center" }}
              width={isCollapseMenuBreakpoint ? "full" : "auto"}
              px={isCollapseMenuBreakpoint ? 4 : 0}
            >
              <Button
                as="a"
                href="https://graph-new.aihouse.com/?owner=in&redirect_uri=https://3d.3vjia.com/oversea"
                target="_blank"
                rel="noreferrer noopener"
                textTransform="uppercase"
                variant="ghost"
                colorScheme="orange"
                letterSpacing="tight"
              >
                Log in
              </Button>
              {/* <Button
                as="a"
                href="https://graph-new.aihouse.com/reg?owner=in"
                target="_blank"
                rel="noreferrer noopener"
                textTransform="uppercase"
                variant="ghost"
                colorScheme="orange"
                letterSpacing="tight"
              >
                Sign up
              </Button> */}
              <Button
                as="a"
                href="https://graph-new.aihouse.com/reg?owner=in"
                target="_blank"
                rel="noreferrer noopener"
                size={isCollapseMenuBreakpoint ? "sm" : "md"}
                colorScheme="orange"
                textTransform="uppercase"
                letterSpacing="tight"
              >
                Get my free trial
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Header;

import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Heading,
  VStack,
  Grid,
  GridItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link } from "gatsby";

type FreeTrialModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const FreeTrialModal: React.FC<FreeTrialModalProps> = props => {
  const [isLargerThan480, isLargerThan768] = useMediaQuery([
    "(min-width: 480px)",
    "(min-width: 768px)",
  ]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      autoFocus={false}
      size={isLargerThan768 ? "3xl" : isLargerThan480 ? "xl" : "sm"}
    >
      <ModalOverlay />
      <ModalContent p={{ base: 3, md: 4, lg: 6 }}>
        <ModalHeader
          textAlign="center"
          textTransform="uppercase"
          color="orange.500"
          fontSize="3xl"
        >
          Free Training
        </ModalHeader>

        <ModalCloseButton size="lg" />

        <ModalBody>
          <VStack spacing="6" textAlign="center">
            <VStack spacing="1" fontSize="xl">
              <Text>
                To experience the software, we would like to invite you to our
              </Text>

              <Text>
                <Text as="span" fontWeight="bold">
                  FREE Training
                </Text>{" "}
                or{" "}
                <Text as="span" fontWeight="bold">
                  DEMO SESSIONS
                </Text>
              </Text>
            </VStack>

            <Text color="orange.500" fontWeight="bold" fontSize="2xl">
              Master 3D Design and Rendering Skills
            </Text>

            <Grid
              templateColumns={{
                base: "repear(1, 1fr)",
                md: "repeat(2, 1fr)",
              }}
              gap="6"
            >
              <GridItem
                as={VStack}
                border="2px"
                borderColor="orange.500"
                rounded="md"
                p="6"
                spacing="6"
                shadow="md"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Heading
                  as="h5"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="lg"
                >
                  Demo session with Q&A
                </Heading>
                <Text fontSize="md">
                  Showcase design features, working tool-sets 3D modeling &
                  rendering.
                </Text>
              </GridItem>

              <GridItem
                as={VStack}
                border="2px"
                borderColor="orange.500"
                rounded="md"
                p="6"
                spacing="6"
                shadow="md"
                _hover={{ transform: "translate(0, -2px)", shadow: "lg" }}
                transitionProperty="all"
                transitionDuration="200ms"
                transitionTimingFunction="linear"
                userSelect="none"
              >
                <Heading
                  as="h5"
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="lg"
                >
                  Two day structured training
                </Heading>
                <Text fontSize="md">
                  Where you or your team can learn and practice in-depth on
                  using the software to design and render effortlessly.
                </Text>
              </GridItem>
            </Grid>

            <Button
              colorScheme="orange"
              variant="outline"
              size="lg"
              as={Link}
              to="/contact-us/"
            >
              Contact Us
            </Button>

            <Text fontSize="lg">
              Get Free Starter Trial of AiHouse Interior Design & Rendering
              software
            </Text>

            <Button
              colorScheme="orange"
              variant="solid"
              size="lg"
              as="a"
              href="https://graph-new.aihouse.com/reg?owner=in"
              target="_blank"
              rel="noreferrer noopener"
            >
              Get my free trial
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default FreeTrialModal;
